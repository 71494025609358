import { initAutoContextNotification } from "../common/auto-context-notification";
import { fetchAutoContextToasterService } from "./services/auto-context-toaster-service";
import { dispatchCustomerAutoContextFailureEvent, dispatchCustomerContextSuccessEvent, dispatchOpeningStoreChoicesModalEvent, dispatchOpenStoreContextLayerEvent } from "../common/event-bus";
import { getAutoContextHtml } from "./services/auto-context-service";
import { getContextBannerHtml } from "./services/context-banner-service";
import { getContextHeaderHtml } from "./services/context-header-service";
import { getRefreshedContextLayerView } from "./services/context-layer-service";
import { addContextHeaderEvent } from "../common/context-header-event";
import { CustomerContextLayer } from "../customercontextlayer/customercontextlayer.src";
class AutoContextualization extends lm.Composant {
  constructor(componentName) {
    super(componentName);
    initAutoContextualization();
  }
}
function initAutoContextualization() {
  const autoContextualizationSelector = getAutoContextualisationSelector();
  if (!autoContextualizationSelector) return;
  const isNotContextualizedAfterConsent = autoContextualizationSelector.dataset.isnotcontextualizedafterconsent === "true";
  const isOpeningModal = autoContextualizationSelector.dataset.isopeningmodal === "true";
  if (isNotContextualizedAfterConsent && isOpeningModal) {
    dispatchOpeningStoreChoicesModalEvent();
  }
  const isAutoContextualized = autoContextualizationSelector.dataset.isautocontextualized;
  if (isAutoContextualized == null) return;
  if (isAutoContextualized === "false") {
    handleAutoContextFail();
    return;
  }
  handleAutoContextSuccess();
}
function getAutoContextualisationSelector() {
  return document.querySelector(`[data-selectorid="auto-contextualization"]`);
}
function getContextBannerSelector() {
  return document.querySelector(`[data-selectorid="context-banner"]`);
}
function getContextHeaderSelector() {
  return document.querySelector(`[data-selectorid="context-header"]`);
}
function getContextLayerSelector() {
  return document.querySelector(`[data-selectorid="component-customer-context-layer"]`);
}
function refreshContextBanner() {
  getContextBannerHtml(contextBannerFragment => {
    const contextBannerSelector = getContextBannerSelector();
    if (!contextBannerSelector) return;
    contextBannerSelector.outerHTML = contextBannerFragment;
  });
}
function refreshContextHeader() {
  getContextHeaderHtml(contextHeaderFragment => {
    const contextHeaderSelector = getContextHeaderSelector();
    if (!contextHeaderSelector) return;
    contextHeaderSelector.outerHTML = contextHeaderFragment;
    addContextHeaderEvent();
  });
}
function refreshContextLayer() {
  getRefreshedContextLayerView(contextLayerFragment => {
    const contextLayerSelector = getContextLayerSelector();
    if (!contextLayerSelector) return;
    contextLayerSelector.outerHTML = contextLayerFragment;
    const customerContextLayerClass = new CustomerContextLayer('CustomerContextLayer');
    customerContextLayerClass.initLayer();
  });
}
export function refreshVisibleFragments() {
  refreshContextBanner();
  refreshContextHeader();
  refreshContextLayer();
}
export function handleAutoContextSuccess() {
  dispatchCustomerContextSuccessEvent();
  const autoContextualizationSelector = getAutoContextualisationSelector();
  if (!autoContextualizationSelector) return;
  const reloadOnContextualizationSuccess = autoContextualizationSelector.dataset.reloadoncontextualizationsuccess === "true";
  if (reloadOnContextualizationSuccess) {
    window.location.replace(autoContextualizationSelector.dataset.redirecturl ??= window.location.href);
  } else {
    fetchAutoContextToasterService(toasterRendering => {
      autoContextualizationSelector.innerHTML = toasterRendering;
      const autoContextToaster = document.querySelector(`[data-selectorid="auto-context-toaster"]`);
      initAutoContextNotification(autoContextToaster);
    });
  }
  refreshVisibleFragments();
}
export function handleAutoContextFail() {
  dispatchCustomerAutoContextFailureEvent();
  const autoContextualizationSelector = getAutoContextualisationSelector();
  if (!autoContextualizationSelector) return;
  const isOpeningLayer = autoContextualizationSelector.dataset.isopeninglayer === "true";
  const isOpeningModal = autoContextualizationSelector.dataset.isopeningmodal === "true";
  if (isOpeningLayer) dispatchOpenStoreContextLayerEvent();
  if (isOpeningModal) dispatchOpeningStoreChoicesModalEvent();
}
export function onUserConsentChange() {
  const optanonConsentCustomizationGroupValue = 'C0003:1';
  const cookieConsent = document.cookie.split(';').filter(cookie => cookie.trim().startsWith('OptanonConsent')).values().next().value;
  decodeURIComponent(cookieConsent).includes(optanonConsentCustomizationGroupValue) ? refreshAutoContextFragment() : handleAutoContextFail();
}
function refreshAutoContextFragment() {
  getAutoContextHtml(autoContextFragment => {
    const autoContextualizationSelector = getAutoContextualisationSelector();
    if (!autoContextualizationSelector) return;
    autoContextualizationSelector.outerHTML = autoContextFragment;
    initAutoContextualization();
  }, handleAutoContextFail);
}
lm.DOMReady(() => {
  new AutoContextualization('autocontextualization');
});